import React from "react";

import { Box, Typography } from "@material-ui/core";

import DalabGrid from "./components/DalabGrid";
import DalabCard from "./components/DalabCard";

import { groupBy } from "./utils";

export const publications = [
  {
    title: "Closed form of the Hessian spectrum for some Neural Networks",
    featured: false,
    url: "https://openreview.net/pdf?id=gW30Rx4eZP",
    authors: "S.P. Singh, T. Hofmann",
    locale: "ICML 2024 HiLD workshop",
    year: 2024
  },
  {
    title: "Theoretical Characterisation of the Loss Landscape Conditioning in Neural Networks",
    featured: false,
    url: "https://openreview.net/pdf?id=fpOnUMjLiO",
    authors: "J. Zhao, S.P. Singh, A. Lucchi",
    locale: "NeurIPS 2024",
    year: 2024
  },
  {
    title: "Landscaping Linear Mode Connectivity",
    featured: false,
    url: "https://openreview.net/pdf/ab41a53471dc83982e8fe6a7f39307eb27709321.pdf",
    authors: "S.P. Singh, L. Adilova, M. Kamp, A. Fischer, B. Schölkopf, T. Hofmann",
    locale: "ICML 2024 HiLD workshop",
    year: 2024
  },
  {
    title: "Hallmarks of Optimization Trajectories in Neural Networks and LLMs: Directional Redundancy and Exploration",
    featured: false,
    url: "https://openreview.net/pdf?id=T24VrBNQD3",
    authors: "S.P. Singh, B. He, T. Hofmann, B. Schölkopf",
    locale: "ICML 2024 TF2M workshop",
    year: 2024
  },
  {
    title: "How to Compute the Probability of a Word",
    featured: false,
    url: "https://aclanthology.org/2024.emnlp-main.1020/",
    authors: "T. Pimentel, C. Meister",
    locale: "EMNLP 2024",
    year: 2024
  },
  {
    title: "Causal Estimation of Memorisation Profiles",
    featured: false,
    url: "https://aclanthology.org/2024.acl-long.834/",
    authors: "P. Lesci, C. Meister, T. Hofmann, A. Vlachos, T. Pimentel",
    locale: "ACL 2024",
    year: 2024
  },
  {
    title: "Local and Global Decoding in Text Generation",
    featured: false,
    url: "https://aclanthology.org/2024.findings-emnlp.854/",
    authors: "D. Gareev, T. Hofmann, E. Krishnasamy, T. Pimentel",
    locale: "Findings of the ACL: EMNLP 2024",
    year: 2024
  },
  {
    title: "Towards a Similarity-adjusted Surprisal Theory",
    featured: false,
    url: "https://aclanthology.org/2024.emnlp-main.921/",
    authors: "C. Meister, M. Giulianelli, T. Pimentel",
    locale: "EMNLP 2024",
    year: 2024
  },
  {
    title: "On the Effect of (Near) Duplicate Subwords in Language Modelling",
    featured: false,
    url: "https://aclanthology.org/2024.findings-acl.571/",
    authors: "A. Schäfer, T. Hofmann, I. Schlag, T. Pimentel",
    locale: "Findings of the ACL: ACL 2024",
    year: 2024
  },
  {
    title: "An information-theoretic analysis of targeted regressions during reading",
    featured: false,
    url: "https://doi.org/10.1016/j.cognition.2024.105765",
    authors: "E. Wilcox, T. Pimentel, C. Meister, R. Cotterell",
    locale: "Cognition 2024",
    year: 2024
  },
  {
    title: "Investigating Critical Period Effects in Language Acquisition through Neural Language Models",
    featured: false,
    url: "https://arxiv.org/abs/2407.19325",
    authors: "I. Constantinescu, T. Pimentel, R. Cotterell, A. Warstadt",
    locale: "TACL 2024",
    year: 2024
  },
  {
    title: "Super Consistency of Neural Network Landscapes and Learning Rate Transfer",
    featured: false,
    url: "https://arxiv.org/abs/2402.17457",
    authors: "L. Noci*, A. Meterez*, T. Hofmann, A. Orvieto",
    locale: "NeurIPS 2024",
    year: 2024
  },
  {
    title: "Exploring the Limits of Feature Learning in Continual Learning",
    featured: false,
    url: "https://openreview.net/forum?id=TYPBYgWyw8",
    authors: "J. Graldi, G. Lanzillotta, L. Noci, B. Grewe, T. Hofmann",
    locale: "NeurIPS 2024 Continual FoMo Workshop",
    year: 2024
  },
  {
    title: "Knowledge Distillation for Teaching Symmetry Invariances",
    featured: false,
    url: "https://openreview.net/forum?id=ZADu3QclDN",
    authors: "P. Odagiu, N. Nobili, F. D. Schrag, Y. Bicker, Y. Ding",
    locale: "NeurIPS 2024 Workshop SciForDL",
    year: 2024
  },
  {
    title: "Recurrent Distance Filtering for Graph Representation Learning",
    featured: false,
    url: "https://arxiv.org/abs/2312.01538",
    authors: "Y. Ding, A. Orvieto, B. He, T. Hofmann",
    locale: "ICML 2024",
    year: 2024
  },
  {
    title: "CLoRA: A Contrastive Approach to Compose Multiple LoRA Models",
    featured: false,
    url: "https://arxiv.org/abs/2403.19776",
    authors: "T. H S Meral*, E. Simsar*, F. Tombari, P. Yanardag",
    locale: "ArXiv",
    year: 2024,
  },
  {
    title: "Stylebreeder: Exploring and Democratizing Artistic Styles through Text-to-Image Models",
    featured: false,
    url: "https://arxiv.org/abs/2406.14599",
    authors: "M. Zheng*, E. Simsar*, H. Yesiltepe, F. Tombari, J. Simon, P. Yanardag",
    locale: "NeurIPS 2024",
    year: 2024,
  },
  {
    title: "How Susceptible are LLMs to Influence in Prompts?",
    featured: false,
    url: "https://arxiv.org/abs/2408.11865",
    authors: "S. Anagnostidis, J. Bulian",
    locale: "COLM 2024 ",
    year: 2024,
  },
  {
    title: "A Language Model's Guide Through Latent Space",
    featured: false,
    url: "https://arxiv.org/abs/2402.14433",
    authors: "D. von Rütte, S. Anagnostidis, G. Bachmann, T. Hofmann",
    locale: "ICML 2024 ",
    year: 2024,
  },
  {
    title: "Navigating Scaling Laws: Compute Optimality in Adaptive Model Training",
    featured: false,
    url: "https://arxiv.org/abs/2311.03233",
    authors: "S. Anagnostidis, G. Bachmann, I. Schlag, T. Hofmann",
    locale: "ICML 2024 ",
    year: 2024,
  },
  {
    title: "The Pitfalls of Next-Token Prediction",
    featured: false,
    url: "https://arxiv.org/abs/2403.06963",
    authors: "G. Bachmann, V. Nagarajan",
    locale: "ICML 2024 ",
    year: 2024,
  },
  {
    title: "Interpolated-MLPs: Controllable Inductive Bias",
    featured: false,
    url: "https://arxiv.org/abs/2410.09655",
    authors: "S. Wu, J. Hong, K. Bai, G Bachmann",
    locale: "ICML 2024 HiLD Workshop on High-dimensional Learning Dynamics",
    year: 2024,
  },
  {
    title: "Unveiling Grokking: Analyzing Feature Learning Dynamics During Training",
    featured: false,
    url: "https://openreview.net/forum?id=gciHssAM8A",
    authors: "J.S. Baustiste, G. Bachmann, B. He, L. Noci, T. Hofmann",
    locale: "ICML 2024 HiLD Workshop on High-dimensional Learning Dynamics",
    year: 2024,
  },
  {
    title: "Towards Meta-Pruning via Optimal Transport",
    featured: false,
    url: "https://arxiv.org/abs/2402.07839",
    authors: "A. Theus, O. Geimer, F. Wicke, T. Hofmann, S. Anagnostidis, S. P. Singh",
    locale: "ICLR 2024 ",
    year: 2024,
  },
  {
    title: "Understanding and Minimising Outlier Features in Transformer Training",
    featured: false,
    url: "https://arxiv.org/abs/2405.19279",
    authors: "B. He, L. Noci, D. Paliotta, I. Schlag, T. Hofmann",
    locale: "NeurIPS 2024",
    year: 2024,
  },
  {
    title: "Simplifying Transformer Blocks",
    featured: false,
    url: "https://arxiv.org/abs/2311.01906",
    authors: "B. He, T. Hofmann",
    locale: "ICLR 2024",
    year: 2024,
  },
  {
    title: "Harnessing Synthetic Datasets: The Role of Shape Bias in Deep Neural Network Generalization",
    featured: false,
    url: "https://arxiv.org/abs/2311.06224",
    authors: "E. Benarous, S. Anagnostidis, L. Biggio, T. Hofmann",
    locale: "NeurIPS 2023 SyntheticData4ML",
    year: 2023,
  },
  {
    title: "The Hessian perspective into the Nature of Convolutional Neural Networks",
    featured: true,
    url: "https://openreview.net/pdf?id=BrOPvKsIXW",
    authors: "S.P. Singh, T. Hofmann, B. Schölkopf",
    locale: "ICML",
    year: 2023,
  },
  {
    title: "How Good is a Single Basin?",
    featured: false,
    url: "https://arxiv.org/abs/2402.03187",
    authors: "K. Lion, L. Noci, T. Hofmann, G. Bachmann",
    locale: "AISTATS 2024 ",
    year: 2024,
  },
  {
    title: "Some Fundamental Aspects about Lipschitz Continuity of Neural Network Functions",
    featured: false,
    url: "https://arxiv.org/pdf/2302.10886",
    authors: "G. Khromov*, S.P. Singh*",
    locale: "ICLR",
    year: 2024,
  },
  {
    title: "Rethinking Attention: Exploring Shallow Feed-Forward Neural Networks as an Alternative to Attention Layers in Transformers",
    featured: false,
    url: "https://arxiv.org/pdf/2311.10642.pdf",
    authors: "V. Bozic, D. Dordevic, D. Coppola, J. Thommes, S.P. Singh",
    locale: "AAAI",
    year: 2024,
  },
  {
    title: "Revisiting the Optimality of Word Lengths",
    featured: false,
    url: "https://aclanthology.org/2023.emnlp-main.137/",
    authors: "T. Pimentel, E.G. Wilcox, C. Meister, K. Mahowald, R. Cotterell",
    locale: "Empirical Methods in Natural Language Processing (EMNLP)",
    year: 2023,
  },
  {
    title: "Language Model Quality Correlates with Psychometric Predictive Power in Multiple Languages",
    featured: false,
    url: "https://aclanthology.org/2023.emnlp-main.466/",
    authors: "E.G. Wilcox, C. Meister, R. Cotterell, T. Pimentel",
    locale: "Empirical Methods in Natural Language Processing (EMNLP)",
    year: 2023,
  },
  {
    title: "Quantifying the redundancy between prosody and text",
    featured: false,
    url: "https://aclanthology.org/2023.emnlp-main.606/",
    authors: "L. Wolf, T. Pimentel, E. Fedorenko, R. Cotterell, A. Warstadt, E.G. Wilcox, T. Regev",
    locale: "Empirical Methods in Natural Language Processing (EMNLP)",
    year: 2023,
  },
  {
    title: "An Exploration of Left-Corner Transformations",
    featured: false,
    url: "https://aclanthology.org/2023.emnlp-main.827/",
    authors: "A. Opedal, E. Tsipidi, T. Pimentel, R. Cotterell, T. Vieira",
    locale: "Empirical Methods in Natural Language Processing (EMNLP)",
    year: 2023,
  },
  {
    title: "Escaping Random Teacher Initialization Enhances Signal Propagation and Representations",
    featured: false,
    url: "https://openreview.net/pdf?id=ClCrg213JS",
    authors: "F. Sarnthein, S.P. Singh, A. Orvieto, T. Hofmann",
    locale: "NeurIPS M3L workshop",
    year: 2023,
  },
  {
    title: "Transformer Fusion with Optimal Transport",
    featured: false,
    url: "https://arxiv.org/pdf/2310.05719.pdf",
    authors: "M. Imfeld*, J. Graldi*, M. Giordano*, T. Hofmann, S. Anagnostidis, S.P. Singh",
    locale: "ICLR 2024",
    year: 2024,
  },
  {
    title: "Local vs Global continual learning",
    featured: false,
    url: "https://arxiv.org/abs/2407.16611",
    authors: "G. Lanzillotta, S.P. Singh, B.F. Grewe, T. Hofmann",
    locale: "CoLLAs",
    year: 2024,
  },
  {
    title: "Towards guarantees for parameter isolation in continual learning",
    featured: false,
    url: "https://arxiv.org/pdf/2310.01165.pdf",
    authors: "G. Lanzillotta, S.P. Singh, B.F. Grewe, T. Hofmann",
    locale: "ArXiv",
    year: 2023,
  },
  {
    title: "On the curvature of the loss landscape",
    featured: false,
    url: "https://arxiv.org/pdf/2307.04719.pdf",
    authors: "A. Pouplin, H. Roy, S.P. Singh, G. Arvanitidis",
    locale: "ArXiv",
    year: 2023,
  },
  {
    title: "Optimal Brain Compression: A Framework for Accurate Post-Training Quantization and Pruning",
    featured: false,
    url: "https://proceedings.neurips.cc/paper_files/paper/2022/file/1caf09c9f4e6b0150b06a07e77f2710c-Paper-Conference.pdf",
    authors: "E. Frantar, S.P. Singh, D. Alistarh",
    locale: "NeurIPS",
    year: 2022,
  },
  {
    title: "LIME: Localized Image Editing via Attention Regularization in Diffusion Models",
    featured: false,
    url: "https://arxiv.org/abs/2312.09256",
    authors: "E. Simsar, A. Tonioni*, Y. Xian*, T. Hofmann, F. Tombari",
    locale: "WACV 2025",
    year: 2025,
  },
  {
    title: "CONFORM: Contrast is All You Need For High-Fidelity Text-to-Image Diffusion Models",
    featured: false,
    url: "https://arxiv.org/abs/2312.06059",
    authors: "T. H S Meral, E. Simsar, F. Tombari, P. Yanardag",
    locale: "CVPR 2024",
    year: 2024,
  },
  {
    title: "Depthwise Hyperparameter Transfer in Residual Networks: Dynamics and Scaling Limit",
    featured: false,
    url: "https://arxiv.org/abs/2309.16620",
    authors: "B. Bordelon*, L. Noci*, M. Li, B. Hanin, C. Pehlevan",
    locale: "Arxiv",
    year: 2023,
  },
  {
    title: "The Shaped Transformer: Attention Models in the Infinite Depth-and-Width Limit",
    featured: true,
    url: "https://arxiv.org/abs/2306.17759",
    authors: "L. Noci*, C. Li*, M. Li*, B. He, T. Hofmann, C. Maddison, D. Roy",
    locale: "NeurIPS",
    year: 2023,
  },
  {
    title: "Scaling MLPs: A Tale of Inductive Bias",
    featured: false,
    url: "https://arxiv.org/abs/2306.13575",
    authors: "G. Bachmann, S. Anagnostidis, T. Hofmann",
    locale: "NeurIPS",
    year: 2023,
  },
  {
    title: "Multi-CLIP: Contrastive Vision-Language Pre-training for Question Answering tasks in 3D Scenes",
    featured: false,
    url: "https://arxiv.org/abs/2306.02329",
    authors: "A. Delitzas, M. Parelli, N. Hars, G. Vlassis, S. Anagnostidis, G. Bachmann, T. Hofmann",
    locale: "BMVC",
    year: 2023,
  },
  {
    title: "Dynamic Context Pruning for Efficient and Interpretable Autoregressive Transformers",
    featured: false,
    url: "https://arxiv.org/abs/2305.15805",
    authors: "S. Anagnostidis, D. Pavllo, L. Biggio, L. Noci, A. Lucchi, T. Hofmann",
    locale: "NeurIPS",
    year: 2023,
  },
  {
    title: "OpenAssistant Conversations--Democratizing Large Language Model Alignment",
    featured: false,
    url: "https://arxiv.org/abs/2304.07327",
    authors: "A. Köpf, Y. Kilcher, D. von Rütte, S. Anagnostidis, ZR Tam, et al",
    locale: "NeurIPS",
    year: 2023,
  },
  {
    title: "Mastering Spatial Graph Prediction of Road Networks",
    featured: false,
    url: "https://arxiv.org/abs/2210.00828",
    authors: "S. Anagnostidis, A. Lucchi, T. Hofmann",
    locale: "ICCV",
    year: 2023,
  },
  {
    title: "Random Teachers are Good Teachers",
    featured: false,
    url: "https://arxiv.org/abs/2302.12091",
    authors: "F. Sarnthein, G. Bachmann, S. Anagnostidis, T. Hofmann",
    locale: "ICML",
    year: 2023,
  },
  {
    title: "The Curious Case of Benign Memorization",
    featured: false,
    url: "https://arxiv.org/abs/2210.14019",
    authors: "S. Anagnostidis*, G. Bachmann*, L. Noci*, T. Hofmann",
    locale: "ICLR",
    year: 2023,
  },
  {
    title: "LatentSwap3D: Semantic Edits on 3D Image GAN",
    featured: false,
    url: "https://arxiv.org/abs/2212.01381",
    authors:
      "E. Simsar, A. Tonioni, E. P Ornek, F. Tombari",
    locale: "ICCV AI3DCC 2023",
    year: 2023,
  },
  {
    title: "Resurrecting Recurrent Neural Networks for Long Sequences",
    featured: false,
    url: "https://arxiv.org/abs/2303.06349",
    authors:
      "A. Orvieto, S. L Smith, A. Gu, A. Fernando, C. Gulcehre, R. Pascanu, S. De",
    locale: "ICML",
    year: 2023,
  },
  {
    title: "An SDE for Modeling SAM: Theory and Insights",
    featured: false,
    url: "https://arxiv.org/abs/2301.08203",
    authors:
      "E. Monzio Compagnoni, L. Biggio, A. Orvieto, H. Kersting, F. N. Proske, A. Lucchi",
    locale: "ICML",
    year: 2023,
  },
  {
    title: "Mean First Exit Times of Ornstein-Uhlenbeck Processes in High Dimensions",
    featured: false,
    url: "https://arxiv.org/pdf/2208.04029.pdf",
    authors:
      "H. Kersting, A. Orvieto, F. Proske, A. Lucchi",
    locale: "Journal of Physics A: Mathematical and Theoretical",
    year: 2023,
  },
  {
    title: "Achieving a Better Stability-Plasticity Trade-off via Auxiliary Networks in Continual Learning",
    featured: false,
    url: "https://arxiv.org/pdf/2303.09483.pdf",
    authors:
      "S. Kim, L. Noci, A. Orvieto, T. Hofmann",
    locale: "CVPR",
    year: 2023,
  },
  {
    title: "Explicit Regularization in Overparametrized Models via Noise Injection",
    featured: false,
    url: "https://arxiv.org/abs/2206.04613",
    authors:
      "A. Orvieto*, A. Raj*, H. Kersting*, F. Bach",
    locale: "AISTATS",
    year: 2023,
  },
  {
    title: "On the Effectiveness of Randomized Signatures as Reservoir for Learning Rough Dynamics",
    featured: false,
    url: "https://arxiv.org/abs/2201.00384?context=cs",
    authors:
      "E. Monzio Compagnoni, A. Scampicchio, L. Biggio, A. Orvieto, T. Hofmann, J. Teichmann",
    locale: "IJCNN",
    year: 2023,
  },
  {
    title: "DeepLSS: Breaking Parameter Degeneracies in Large-Scale Structure with Deep-Learning Analysis of Combined Probes",
    featured: false,
    url: "https://journals.aps.org/prx/abstract/10.1103/PhysRevX.12.031029",
    authors:
      "T. Kacprzak, J. Fluri",
    locale: "PHYSICAL REVIEW X",
    year: 2022,
  },
  {
    title: "Dark energy survey year 3 results: Cosmology with peaks using an emulator approach",
    featured: false,
    url: "https://academic.oup.com/mnras/article/511/2/2075/6511572?login=false",
    authors:
      "D Zürcher, J Fluri, R Sgier, T Kacprzak et al.",
    locale: "Monthly Notices of the Royal Astronomical Society",
    year: 2022,
  },
  {
    title: "Symbolic implementation of extensions of the PyCosmo Boltzmann solver",
    featured: false,
    url: "https://www.sciencedirect.com/science/article/pii/S2213133722000373",
    authors:
      "B. Moser, C. S. Lorenz, U. Schmitt, A. Réfrégier, J. Fluri, R. Sgier, F.Tarsitano, L. Heisenberg",
    locale: "Astronomy and Computing",
    year: 2022,
  },
  {
    title: "A tomographic spherical mass map emulator of the KiDS-1000 survey using conditional generative adversarial networks",
    featured: false,
    url: "https://iopscience.iop.org/article/10.1088/1475-7516/2022/12/013/meta",
    authors:
      "T. Wing Hei Yiu, J. Fluri, T. Kacprzak",
    locale: "Journal of Cosmology and Astroparticle Physics",
    year: 2022,
  },
  {
    title: "Synthesizing Speech from Intracranial Depth Electrodes using an Encoder-Decoder Framework",
    featured: false,
    url: "https://nbdt.scholasticahq.com/article/57524",
    authors:
      "J. Kohler, M. C. Ottenhoff, S. Goulis, M. Angrick, A. J. Colon, L. Wagner, S. Tousseyn, P. L. Kubben, C. Herff",
    locale: "Neurons, Behavior, Data analysis, and Theory",
    year: 2022,
  },
  {
    title: "Cosmology from Galaxy Redshift Surveys with PointNet",
    featured: false,
    url: "https://arxiv.org/abs/2211.12346",
    authors:
      "S. Anagnostidis, A. Thomsen, T. Kacprzak, T. Tröster, L. Biggio, A. Refregier, T. Hofmann",
    locale: "Machine Learning and the Physical Sciences, NeurIPS Workshop",
    year: 2022,
  },
  {
    title: "Decoding a Neural Retriever's Latent Space for Query Suggestion",
    featured: true,
    url: "https://arxiv.org/abs/2210.12084",
    authors:
      "L. Adolphs, M. Chen Huebscher, C. Buck, S. Girgin, O. Bachem, M. Ciaramita, T. Hofmann",
    locale: "EMNLP 2022",
    year: 2022,
  },
  {
    title:
      "Language models that seek for knowledge: Modular search & generation for dialogue and prompt completion",
    featured: false,
    url: "https://arxiv.org/abs/2203.13224",
    authors:
      "K. Shuster, M. Komeili, L. Adolphs, S. Roller, A. Szlam, J. Weston",
    locale: "EMNLP Findings 2022",
    year: 2022,
  },
  {
    title:
      "Reason first, then respond: Modular generation for knowledge-infused dialogue",
    featured: false,
    url: "https://arxiv.org/abs/2111.05204",
    authors: "L. Adolphs, K. Shuster, J. Urbanek, A. Szlam, J. Weston",
    locale: "EMNLP Findings 2022",
    year: 2022,
  },
  {
    title: "Boosting search engines with interactive agents",
    featured: false,
    url: "https://arxiv.org/abs/2109.00527",
    authors:
      "L. Adolphs, B. Börschinger, C. Buck, M. Chen Huebscher, M. Ciaramita, L. Espeholt, T. Hofmann, Y. Kilcher, S. Rothe, P. Giuseppe Sessa, L. Sestorain",
    locale: "TMLR June 2022",
    year: 2022,
  },
  {
    title:
      "On the Theoretical Properties of Noise Correlation in Stochastic Optimization",
    featured: false,
    url: "https://arxiv.org/abs/2209.09162",
    authors: "H. Kersting, A. Orvieto, F. Bach, F. Proske, A. Lucchi",
    locale: "Neural Information Processing Systems (NeurIPS), 2022",
    year: 2022,
  },

  {
    title:
      "Dynamics of SGD with Stochastic Polyak Stepsizes: Truly Adaptive Variants and Convergence to Exact Solution",
    featured: false,
    url: "https://arxiv.org/pdf/2205.04583.pdf",
    authors: "A. Orvieto, S. Lacoste-Julien, N. Loizou",
    locale: "Neural Information Processing Systems (NeurIPS), 2022",
    year: 2022,
  },
  {
    title: "Mastering Spatial Graph Prediction of Road Networks",
    featured: false,
    url: "https://arxiv.org/abs/2210.00828",
    authors: "S. Anagnostidis, A. Lucchi, T. Hofmann",
    locale: "arxiv",
    year: 2022,
  },
  {
    title:
      "Signal Propagation in Transformers: Theoretical Perspectives and the Role of Rank Collapse",
    featured: true,
    // blog_url: '/blog_post/SignalPropagationinTransformers:TheoreticalPerspectivesandtheRoleofRankCollapse',
    url: "https://arxiv.org/abs/2206.03126",
    authors:
      "L. Noci, S. Anagnostidis, L. Biggio, A. Orvieto, S.P. Singh, A. Lucchi",
    locale: "Neural Information Processing Systems (NeurIPS), 2022",
    year: 2022,
  },
  {
    title: "How Tempering Fixes Data Augmentation in Bayesian Neural Networks",
    featured: true,
    url: "https://arxiv.org/abs/2205.13900",
    authors: "G. Bachmann*, L. Noci*, T. Hofmann",
    locale: "International Conference on Machine Learning (ICML), 2022",
    year: 2022,
  },
  {
    title: "Anticorrelated Noise Injection for Improved Generalization",
    featured: false,
    url: "https://arxiv.org/pdf/2202.02831.pdf",
    authors: "A. Orvieto*, H. Kersting*, F. Proske, F. Bach, A. Lucchi",
    locale: "ICML, 2022",
    year: 2022,
  },

  {
    title:
      "Faster Single-loop Algorithms for Minimax Opt. without Strong Concavity",
    featured: false,
    url: "https://arxiv.org/abs/2112.05604",
    authors: "J. Yang, A. Orvieto, A. Lucchi, N. He",
    locale: "AISTATS, 2022",
    year: 2022,
  },

  {
    title: "Vanishing Curvature in Randomly Initialized Deep ReLU Networks",
    featured: false,
    url: "https://arxiv.org/abs/2106.03763",
    authors: "A. Orvieto*, J. Kohler*, D. Pavllo, T. Hofmann, A. Lucchi",
    locale: "AISTATS 2022",
    year: 2022,
  },
  {
    title: "Phenomenology of Double Descent in Finite-Width Neural Networks",
    featured: true,
    url: "https://openreview.net/forum?id=lTqGXfn9Tv",
    authors: "S.P. Singh, A. Lucchi, T. Hofmann, B. Schölkopf",
    locale: "International Conference on Learning Representations (ICLR), 2022",
    year: 2022,
  },

  {
    title: "Generalization Through The Lens Of Leave-One-Out Error",
    featured: true,
    url: "https://openreview.net/forum?id=7grkzyj89A_",
    authors: "G. Bachmann, A. Lucchi, T. Hofmann",
    locale: "International Conference on Learning Representations (ICLR), 2022",
    year: 2022,
  },
  {
    title:
      "Precise characterization of the prior predictive distribution of deep ReLU networks",
    featured: true,
    url: "https://arxiv.org/abs/2106.06615",
    authors: "L. Noci*, G. Bachmann*, K. Roth*, S. Nowozin, T. Hofmann",
    locale: "Neural Information Processing Systems (NeurIPS), 2021",
    year: 2021,
  },
  {
    title:
      "Analytic Insights into Structure and Rank of Neural Network Hessian Maps",
    featured: true,
    url: "https://arxiv.org/abs/2106.16225",
    authors: "S.P. Singh, G. Bachmann, T. Hofmann",
    locale: "Neural Information Processing Systems (NeurIPS), 2021",
    year: 2021,
  },
  {
    title:
      "Rethinking the Variational Interpretation of Nesterov Accelerated Method",
    featured: false,
    url: "https://arxiv.org/abs/2107.05040",
    authors: "P. Zhang*, A. Orvieto*, H. Daneshmand",
    locale: "Neural Information Processing Systems (NeurIPS), 2021",
    year: 2021,
  },
  {
    title:
      "Disentangling the Roles of Curation, Data-Augmentation and the Prior in the Cold Posterior Effects",
    featured: false,
    url: "https://arxiv.org/abs/2106.06596",
    authors: "L. Noci*, K . Roth*, G. Bachmann*, S. Nowozin, T. Hofmann",
    locale: "Neural Information Processing Systems (NeurIPS), 2021",
    year: 2021,
  },

  {
    title:
      "On the Second-order Convergence Properties of Random Search Methods",
    featured: false,
    url: "https://arxiv.org/abs/2110.13265",
    authors: "A. Lucchi*, A. Orvieto*, A. Solomou*",
    locale: "Neural Information Processing Systems (NeurIPS), 2021",
    year: 2021,
  },

  {
    title: "Differentiable Strong Lensing for Complex Lens Modelling",
    featured: false,
    url: "https://openreview.net/forum?id=Gvwve9BMqbr",
    authors: "L. Biggio, A. Galan, A. Peel, G. Vernardos, F. Courbin",
    locale: "NeurIPS 2021 workshop: Machine Learning and the Physical Sciences",
    year: 2021,
  },

  {
    title: "Empirics on the expressiveness of Randomized Signature",
    featured: false,
    url: "https://openreview.net/forum?id=Gvwve9BMqbr",
    authors: "E. Monzio Compagnoni, L. Biggio, A. Orvieto",
    locale:
      "NeurIPS 2021 workshop: The Symbiosis of Deep Learning and Differential Equations",
    year: 2021,
  },

  {
    title:
      "Cosmological parameter estimation and inference using deep summaries",
    featured: "true",
    url: "https://journals.aps.org/prd/abstract/10.1103/PhysRevD.104.123526",
    authors: "J. Fluri, T. Kacprzak, A. Refregier, A. Lucchi, and T. Hofmann",
    locale: "Physical Review D 104, 2021",
    year: 2021,
  },
  {
    title: "The power of quantum neural networks",
    featured: false,
    url: "https://www.nature.com/articles/s43588-021-00084-1.epdf?sharing_token=KgK74wI2Y6KFni8tN0SY6NRgN0jAjWel9jnR3ZoTv0OvSBlVJ7wtYX-Af8G220lXDJyuIMpO5jnusqLQAVse5m9ZcRs-QQUI1I34SHRajSc3wVxpQsG1-BQiQdw8Q1ePA6smEnE6H4-wbzQDuqoCjXRP1_8mFvjU4ANx0XuA3R8%3D",
    authors: "A. Abbas, D. Sutter, C. Zoufal, A. Lucchi, A. Figalli, S Woerner",
    locale: "Nature computational science, 2021",
    year: 2021,
  },
  {
    title: "Variational Quantum Boltzmann Machines",
    featured: false,
    url: "https://arxiv.org/abs/2006.06004",
    authors: "C. Zoufal, A. Lucchi, S. Woerner",
    locale: "Quantum Machine Intelligence, 2021",
    year: 2021,
  },
  {
    title:
      "Emulation of cosmological mass maps with conditional generative adversarial networks",
    featured: false,
    url: "https://www.frontiersin.org/articles/10.3389/frai.2021.673062/full",
    authors: "N. Perraudin, S. Marcon, A. Lucchi, T. Kacprzak",
    locale: "Frontiers Artif. Intel, 2021",
    year: 2021,
  },
  {
    title: "Neural Symbolic Regression that Scales",
    featured: true,
    url: "https://arxiv.org/abs/2106.06427",
    authors: "L. Biggio, T. Bendinelli, A. Neitz, A. Lucchi, G. Parascandolo",
    locale: "International Conference on Machine Learning (ICML), 2021",
    year: 2021,
  },
  {
    title: "Uniform Convergence, Adversarial Spheres and a Simple Remedy",
    featured: false,
    url: "https://arxiv.org/abs/2105.03491",
    authors: "G. Bachmann, S. Moosavi-Dezfooli, T. Hofmann",
    locale: "International Conference on Machine Learning (ICML), 2021",
    year: 2021,
  },
  {
    title:
      "Learning Generative Models of Textured 3D Meshes from Real-World Images",
    featured: true,
    url: "https://arxiv.org/abs/2103.15627",
    authors: "D. Pavllo, J. Kohler, T. Hofmann, A. Lucchi",
    locale: "International Conference on Computer Vision (ICCV), 2021",
    year: 2021,
  },
  {
    title: "Learning explanations that are hard to vary",
    featured: false,
    url: "https://arxiv.org/abs/2009.00329",
    authors: "G. Parascandolo, A. Neitz, A. Orvieto, L. Gresele, B. Schölkopf",
    locale: "International Conference on Learning Representations (ICLR), 2021",
    year: 2021,
  },

  {
    title: "Direct-Search Methods for a Class of Non-Convex Min-Max Games",
    featured: "false",
    url: "http://proceedings.mlr.press/v130/anagnostidis21a/anagnostidis21a.pdf",
    authors: "S-K. Anagnostidis, A. Lucchi, Y. Diouane",
    locale: "AISTATS, 2021",
    year: 2021,
  },
  {
    title: "Scalable Graph Networks for Particle Simulations",
    featured: "false",
    url: "https://arxiv.org/abs/2010.06948",
    authors: "K. Martinkus, A. Lucchi, N. Perraudin",
    locale: "AAAI, 2021",
    year: 2021,
  },

  {
    title:
      "Revisiting the Role of Symplectic Numerical Integration on Acceleration and Stability in Convex Optimization",
    featured: false,
    url: "https://arxiv.org/pdf/2102.11537.pdf",
    authors: "P. Zhang, A. Orvieto, H. Daneshmand, R. Smith, T. Hofmann",
    locale:
      "International Conference on Artificial Intelligence and Statistics (AISTATS), 2021",
    year: 2021,
  },

  {
    title: "Momentum Improves Optimization on Riemannian Manifolds",
    featured: false,
    url: "https://arxiv.org/abs/2002.04144",
    authors: "F. Alimisis, A. Orvieto, G. Becigneul, A. Lucchi",
    locale:
      "International Conference on Artificial Intelligence and Statistics (AISTATS), 2021",
    year: 2021,
  },
  {
    title:
      "Computationally Tractable Riemannian Manifolds for Graph Embeddings",
    featured: false,
    url: "https://arxiv.org/pdf/2002.08665.pdf",
    authors: "C. Cruceru, G. Becigneul, O. Ganea",
    locale:
      "Association for the Advancement of Artificial Intelligence (AAAI 2021)",
    year: 2021,
  },
  {
    title:
      "Algorithmic Recourse: from Counterfactual Explanations to Interventions",
    featured: false,
    url: "https://arxiv.org/pdf/2002.06278.pdf",
    authors: "A-H. Karimi, B. Schölkopf, I. Valera",
    locale:
      "Conference on Fairness, Accountability, and Transparency (ACM FAccT), 2021",
    year: 2021,
  },
  {
    title:
      "Algorithmic recourse under imperfect causal knowledge: a probabilistic approach",
    featured: false,
    url: "https://arxiv.org/pdf/2006.06831.pdf",
    authors: "A-H. Karimi, J. von Kügelgen, B. Schölkopf, I. Valera",
    locale: "Neural Information Processing Systems (NeurIPS), 2020",
    year: 2020,
  },

  {
    title: "Convolutional Generation of Textured 3D Meshes",
    featured: false,
    url: "https://arxiv.org/pdf/2006.07660.pdf",
    authors: "D. Pavllo, G. Spinks, T. Hofmann, M.F. Moens, A. Lucchi",
    locale: "Neural Information Processing Systems (NeurIPS), 2020",
    year: 2020,
  },
  {
    title:
      "WoodFisher: Efficient Second-Order Approximation for Neural Network Compression",
    featured: false,
    url: "https://arxiv.org/pdf/2004.14340.pdf",
    authors: "S.P. Singh, D. Alistarh",
    locale: "Neural Information Processing Systems (NeurIPS), 2020",
    year: 2020,
  },

  {
    title: "Model Fusion via Optimal Transport",
    featured: false,
    url: "https://arxiv.org/pdf/1910.05653.pdf",
    authors: "S. P. Singh, M. Jaggi",
    locale: "Neural Information Processing Systems (NeurIPS), 2020",
    year: 2020,
  },

  {
    title:
      "Hold me tight! Influence of discriminative features on deep network boundaries",
    featured: false,
    url: "https://arxiv.org/pdf/2002.06349.pdf",
    authors: "G. Ortiz-Jimenez, A. Modas, S.Moosavi-Dezfooli, P. Frossard",
    locale: "Neural Information Processing Systems (NeurIPS), 2020",
    year: 2020,
  },

  {
    title: "Neural Anisotropy Directions",
    featured: false,
    url: "https://arxiv.org/pdf/2006.09717.pdf",
    authors: "G. Ortiz-Jimenez, A. Modas, S. Moosavi-Dezfooli, P. Frossard",
    locale: "Neural Information Processing Systems (NeurIPS), 2020",
    year: 2020,
  },

  {
    title:
      "Controlling Style and Semantics in Weakly-Supervised Image Generation",
    featured: false,
    url: "https://arxiv.org/pdf/1912.03161.pdf",
    authors: "D. Pavllo, A. Lucchi, T. Hofmann",
    locale: "European Conference on Computer Vision (ECCV), 2020",
    year: 2020,
  },

  {
    title: "GeoDA: a geometric framework for black-box adversarial attacks",
    featured: false,
    url: "https://arxiv.org/pdf/2003.06468.pdf",
    authors: "A. Rahmati, S. Moosavi-Dezfooli, P. Frossard, H. Dai",
    locale:
      "IEEE Conference on Computer Vision and Pattern recognition (CVPR), 2020",
    year: 2020,
  },

  {
    title:
      "Batch normalization provably avoids ranks collapse for randomly initialised deep networks",
    featured: false,
    url: "https://arxiv.org/pdf/2003.01652.pdf",
    authors: "H. Daneshmand, J. Kohler, F. Bach, T. Hofmann, A. Lucchi",
    locale: "Neural Information Processing Systems (NeurIPS), 2020",
    year: 2020,
  },

  {
    title:
      "Adversarial Training is a Form of Data-dependent Operator Norm Regularization",
    featured: false,
    url: "https://arxiv.org/abs/1906.01527",
    authors: "K. Roth, Y. Kilcher, T. Hofmann",
    locale: "Neural Information Processing Systems (NeurIPS), 2020",
    year: 2020,
  },

  {
    title: "Two-Level K-FAC Preconditioning for Deep Learning",
    featured: false,
    url: "https://arxiv.org/pdf/2011.00573.pdf",
    authors: "N. Tselepidis, J. Kohler, A. Orvieto",
    locale:
      "OPT2020: 12th Annual Workshop on Optimization for Machine Learning",
    year: 2020,
  },

  {
    title: "A Seq2Seq approach to Symbolic Regression",
    featured: false,
    url: "https://openreview.net/pdf?id=W7jCKuyPn1",
    authors: "L. Biggio, T. Bendinelli, A. Lucchi, G. Parascandolo",
    locale:
      "Workshop on Learning Meets Combinatorial Algorithms (NeurIPS 2020)",
    year: 2020,
  },

  {
    title: "Uncertainty-aware Remaining Useful Life predictors",
    featured: false,
    url: "https://ml4eng.github.io/camera_readys/19.pdf",
    authors: "L. Biggio, M. Arias Chao, O. Fink",
    locale:
      "Workshop on machine learning for engineering modeling, simulation and design (NeurIPS 2020)",
    year: 2020,
  },

  {
    title:
      "Prognostics and Health Management of Industrial Assets: Current Progress and Road Ahead",
    featured: false,
    url: "https://www.frontiersin.org/articles/10.3389/frai.2020.578613/full",
    authors: "L. Biggio, I. Kastanis",
    locale: "Frontiers in Artificial Intelligence, 2020",
    year: 2020,
  },

  {
    title:
      "Randomized Block-Diagonal Preconditioning for Parallel Learning\r\n",
    featured: false,
    url: "https://arxiv.org/abs/2006.13591",
    authors: "\tC.Mendler-Dünner, A. Lucchi",
    locale: "International Conference on Machine Learning (ICML) 2020",
    year: 2020,
  },

  {
    title: "An Accelerated DFO Algorithm for Finite-sum Convex Functions\r\n",
    featured: false,
    url: "https://arxiv.org/abs/2007.03311",
    authors: "\tY. Chen, A. Orvieto, A. Lucchi",
    locale: "International Conference on Machine Learning (ICML) 2020",
    year: 2020,
  },

  {
    title: "Constant Curvature Graph Convolutional Networks\r\n",
    featured: false,
    url: "https://arxiv.org/abs/1911.05076",
    authors: "\tG. Bachmann, G. Becigneul, O. Ganea",
    locale: "International Conference on Machine Learning (ICML) 2020",
    year: 2020,
  },

  {
    title:
      "How Good is the Bayes Posterior in Deep Neural Networks Really?\r\n",
    featured: false,
    url: "https://arxiv.org/abs/2002.02405",
    authors: "\tF. Wenzel, K. Roth et al.",
    locale: "International Conference on Machine Learning (ICML) 2020",
    year: 2020,
  },

  {
    title:
      "The k-tied Normal Distribution: A Compact Parameterization of Gaussian Mean Field Posteriors in Bayesian Neural Networks\r\n",
    featured: false,
    url: "https://arxiv.org/abs/2002.02655",
    authors: "\tJ. Swiatkowski et al.",
    locale: "International Conference on Machine Learning (ICML) 2020",
    year: 2020,
  },
  {
    title:
      "LeDeepChef: Deep Reinforcement Learning Agent for Families of Text-Based Games\r\n",
    featured: false,
    url: "https://arxiv.org/abs/1909.01646",
    authors: "\tL. Adolphs, T. Hofmann",
    locale: "AAAI Conference on Artificial Intelligence, 2020",
    year: 2020,
  },
  {
    title:
      "A Continuous-time Perspective for Modeling Acceleration in Riemannian Optimization\r\n",
    url: "https://arxiv.org/abs/1910.10782",
    authors: "\tF. Alimisis, A. Orvieto, G. Bécigneul, A. Lucchi",
    locale:
      "International Conference on Artificial Intelligence and Statistics (AISTATS), 2020",
    year: 2020,
  },
  {
    title:
      "Quantum Generative Adversarial Networks for Learning and Loading Random Distributions\r\n",
    url: "https://arxiv.org/abs/1904.00043",
    authors: "\tC. Zoufal, A. Lucchi, S. Woerner",
    locale: "npj Quantum Information, 2019., 2019",
    year: 2019,
  },
  {
    title:
      "Cosmological constraints with deep learning from KiDS-450 weak lensing maps",
    url: "https://arxiv.org/abs/1906.03156",
    authors:
      "\tJ. Fluri, T. Kacprzak, A. Lucchi, A. Refregier, A. Amara, T. Hofmann, A. Schneider",
    locale: "Physical Review D, 2019",
    year: 2019,
  },
  {
    title: "Topological Map Extraction from Overhead Images, ICCV, 2019.\r\n",
    url: "https://arxiv.org/abs/1812.01497",
    authors: "\tZ. Li, J.D. Wegner, A. Lucchi",
    locale: "International Conference on Computer Vision (ICCV), 2019",
    year: 2019,
  },
  {
    title:
      "Breaking the Softmax Bottleneck via Learnable Monotonic Pointwise Non-linearities",
    url: "https://arxiv.org/abs/1902.08077",
    authors: "\tOE Ganea, S Gelly, G Bécigneul, A Severyn",
    locale: "International Conference on Machine Learning (ICML), 2019",
    year: 2019,
  },
  {
    title: "Generalization in Generation: A closer look at Exposure Bias",
    url: "https://arxiv.org/abs/1910.00292",
    authors: "\tFlorian Schmidt",
    locale:
      "Workshop on Neural Generation and Translation (WNGT) at EMNLP, 2019",
    year: 2019,
  },
  {
    title: "Autoregressive Text Generation Beyond Feedback Loops",
    url: "https://arxiv.org/abs/1908.11658",
    authors: "    Florian Schmidt, Stephan Mandt, Thomas Hofmann",
    locale:
      "Conference on Empirical Methods in Natural Language Processing (EMNLP), 2019",
    year: 2019,
  },
  {
    title: "Poincaré GloVe: Hyperbolic Word Embeddings",
    url: "https://arxiv.org/pdf/1810.06546",
    authors: "\tA Tifrea*, G Bécigneul*, OE Ganea*",
    locale: "International Conference on Learning Representations (ICLR), 2019",
    year: 2019,
  },
  {
    title: "Riemannian Adaptive Optimization Methods",
    url: "https://arxiv.org/pdf/1810.00760",
    authors: "\tG Bécigneul, OE Ganea",
    locale: "International Conference on Learning Representations (ICLR), 2019",
    year: 2019,
  },
  {
    title:
      "The Odds are Odd: A Statistical Test for Detecting Adversarial Examples",
    url: "https://arxiv.org/abs/1902.04818",
    authors: "\tK Roth, Y Kilcher, T Hofmann ",
    locale: "International Conference on Machine Learning (ICML), 2019",
    year: 2019,
  },
  {
    title: "The Role of Memory in Stochastic Optimization",
    url: "https://arxiv.org/abs/1907.01678",
    authors: "\tAntonio Orvieto, Jonas Kohler, Aurelien Lucchi",
    locale: "Conference on Uncertainty in Artificial Intelligence (UAI), 2019",
    year: 2019,
  },
  {
    title: "Continuous-time Models for Stochastic Optimization Algorithms",
    url: "https://arxiv.org/abs/1810.02565",
    authors: "\tAntonio Orvieto, Aurelien Lucchi",
    locale:
      "NeurIPS'19: Conference on Neural Information Processing Systems, 2019",
    year: 2019,
  },
  {
    title: "Shadowing Properties of Optimization Algorithms",
    url: "http://people.inf.ethz.ch/orvietoa/shadowing.pdf",
    authors: "\tAntonio Orvieto, Aurelien Lucchi",
    locale:
      "NeurIPS'19: Conference on Neural Information Processing Systems, 2019",
    year: 2019,
  },
  {
    title: "Local Saddle Point Optimization: A Curvature Exploitation Approach",
    url: "https://arxiv.org/abs/1805.05751",
    featured: false,
    authors:
      "\tLeonard Adolphs, Hadi Daneshmand, Aurelien Lucchi, Thomas Hofmann",
    locale:
      "AISTATS'19: The 23rd International Conference on Artificial Intelligence and Statistics, 2019",
    year: 2019,
  },
  {
    title:
      "Cosmological constraints from noisy convergence maps through deep learning.\r\n",
    url: "https://arxiv.org/abs/1807.08732",
    authors:
      " J. Fluri, T. Kacprzak, A. Lucchi, A. Refregier, A. Amara, T. Hofmann ",
    locale: "Physical Review , 2018",
    year: 2018,
  },
  {
    title: "Deep State Space Models for Uncoditional Word Generation",
    url: "https://arxiv.org/pdf/1806.04550",
    authors: "    F Schmidt, T Hofmann ",
    locale:
      "NeurIPS'18: Conference on Neural Information Processing Systems , 2018",
    year: 2018,
  },
  {
    title: "Hyperbolic Neural Networks",
    url: "https://arxiv.org/pdf/1805.09112",
    authors: "    OE Ganea, G Bécigneul, T Hofmann ",
    locale:
      "NeurIPS'18: Conference on Neural Information Processing Systems , 2018",
    year: 2018,
  },
  {
    title: "End-to-End Neural Entity Linking",
    url: "https://arxiv.org/abs/1808.07699",
    authors: "\tN. Kolitsas, OE Ganea, T Hofmann ",
    locale:
      "Conference on Computational Natural Language Learning (CoNLL), 2018",
    year: 2018,
  },
  {
    title: "Hyperbolic Entailment Cones for Learning Hierarchical Embeddings",
    url: "https://arxiv.org/pdf/1804.01882",
    authors: "\tOE Ganea, G Bécigneul, T Hofmann ",
    locale: "International Conference on Machine Learning (ICML), 2018",
    year: 2018,
  },
  {
    title: "A distributed Second-Order Algorithm You Can Trust",
    url: "https://arxiv.org/abs/1806.07569",
    authors: "   C Dünner, A Lucchi, M Gargiani, A Bian, T Hofmann, M Jaggi ",
    locale: "International Conference on Machine Learning (ICML), 2018",
    year: 2018,
  },
  {
    title: "Escaping Saddles with Stochastic Gradients",
    featured: false,
    url: "https://arxiv.org/pdf/1803.05999",
    authors: "\tH Daneshmand, J Kohler, A Lucchi, T Hofmann ",
    locale: "International Conference on Machine Learning (ICML), 2018",
    year: 2018,
  },
  {
    title:
      "Unsupervised Learning of Parsimonious General-Purpose Embeddings for User and Location Modelling",
    url: "https://arxiv.org/pdf/1704.03507",
    authors: "\tJ. Yang and C. Eickhoff",
    locale: "ACM Transaction on Information Systems (TOIS), 2018",
    year: 2018,
  },
  {
    title:
      "Biomedical Question Answering via Weighted Neural Network Passage Retrieval",
    url: "",
    authors: "        F. Galkó and C. Eickhoff",
    locale:
      "Proceedings of the 40th European Conference on Information Retrieval (ECIR), 2018",
    year: 2018,
  },
  {
    title: "Web2Text: Deep Structured Boilerplate Removal",
    url: "",
    authors: "        T. Vogels, O-E. Ganea and C. Eickhoff",
    locale:
      "Proceedings of the 40th European Conference on Information Retrieval (ECIR), 2018",
    year: 2018,
  },
  {
    title: "Cognitive Biases in Crowdsourcing",
    url: "",
    authors: "\tC. Eickhoff",
    locale:
      "Proceedings of the 11th ACM International Conference on Web Search and Data Mining (WSDM), 2018",
    year: 2018,
  },
  {
    title: "Semantic Interpolation in Implicit Models",
    url: "https://arxiv.org/abs/1710.11381",
    authors: "\tY Kilcher, A Lucchi, T Hofmann",
    locale: "International Conference on Learning Representations (ICLR), 2018",
    year: 2018,
  },
  {
    title:
      "Stabilizing Training of Generative Adversarial Networks through Regularization",
    url: "https://arxiv.org/abs/1705.09367",
    authors: "    K. Roth, A. Lucchi, S.Nowozin and T. Hofmann",
    locale: "Conference on Neural Information Processing Systems (NIPS), 2017",
    year: 2017,
  },
  {
    title:
      "Fully Character-Level Neural Machine Translation without Explicit Segmentation",
    url: "https://arxiv.org/abs/1610.03017",
    authors: "        J. Lee, K. Cho and T. Hofmann",
    locale:
      "Transactions of the Association for Computational Linguistics (TACL), 2017",
    year: 2017,
  },
  {
    title: "Learning Aerial Image Segmentation from Online Maps",
    url: "https://arxiv.org/pdf/1707.06879.pdf",
    authors:
      "        Kaiser, P., Wegner, J.D., Lucchi, A., Jaggi, M., Hofmann, T., Schindler, K",
    locale:
      "IEEE Transactions on Geoscience and Remote Sensing, vol. 55(11), 2017, pp. 6054 - 6068.",
    year: 2017,
  },
  {
    title: "Deep Joint Entity Disambiguation with Local Neural Attention",
    url: "https://arxiv.org/abs/1704.04920",
    authors: "        O-E. Ganea and T. Hofmann",
    locale:
      "Conference on Empirical Methods in Natural Language Processing (EMNLP), 2017",
    year: 2017,
  },
  {
    title: "ETH Zurich at TREC Precision Medicine 2017",
    url: "",
    authors:
      "\tN. Foroutan Eghlidi, J. Griner, N. Mesot, L. von Werra and C. Eickhoff",
    locale: "Proceedings of the 26th Text Retrieval Conference (TREC), 2017",
    year: 2017,
  },
  {
    title: "Introduction to the Special Issue on Search as Learning",
    url: "https://link.springer.com/article/10.1007/s10791-017-9315-9",
    authors: "\tC. Eickhoff, J. Gwizdka, C. Hauff and J. He",
    locale: "Information Retrieval Journal (20:5), Springer, 2017",
    year: 2017,
  },
  {
    title: "Evaluating Music Recommender Systems for Groups",
    url: "https://arxiv.org/abs/1707.09790",
    authors: "\tZ. Mezei and C. Eickhoff",
    locale:
      "Proceedings of the ACM RecSys Workshop on Value-Aware Multi-Stakeholder Recommendation (VAMS), 2017",
    year: 2017,
  },
  {
    title:
      "Overview of ImageCLEFcaption 2017 – Caption Prediction and Concept Detection for Biomedical Images",
    url: "http://ceur-ws.org/Vol-1866/invited_paper_7.pdf",
    authors:
      "\tC. Eickhoff, I. Schwall, A. García Seco De Herrera and H. Müller",
    locale: "CEUR-WS Proceedings, 2017",
    year: 2017,
  },
  {
    title:
      "Ranking and Feedback-based Stopping for Recall-centric Document Retrieval",
    url: "https://pdfs.semanticscholar.org/f7d2/f583d16c17dbe33d847b1abb55227d7e410d.pdf",
    authors: "\tN. Hollmann and C. Eickhoff",
    locale:
      "Proceedings of the International Conference of the Cross-Language Evaluation Forum for European Languages (CLEF), 2017",
    year: 2017,
  },
  {
    title: "A Semi-supervised Framework for Image Captioning",
    url: "https://arxiv.org/pdf/1611.05321.pdf",
    authors: "    W. Chen, A. Lucchi, T. Hofmann",
    locale: "iv, 24-Jun-2017",
    year: 2017,
  },
  {
    title: "Overview of ImageCLEF 2017: Information Extraction from Images",
    url: "https://link.springer.com/chapter/10.1007/978-3-319-65813-1_28",
    authors:
      "\tB. Ionescu, H. Müller, M. Villegas, H. Arenas, G. Boato, D. Dang-Nguyen, Y. Dicente Cid, C. Eickhoff, A. García Seco De Herrera, C. Gurrin, M. B. Islam, V. Kovalev, V. Liautchuk, J. Mothe, L. Piras, M. Ziegler and I. Schwall",
    locale:
      "Proceedings of the International Conference of the Cross-Language Evaluation Forum for European Languages (CLEF), 2017",
    year: 2017,
  },
  {
    title:
      "Leveraging Large Amounts of Weakly Supervised Data for\r\nMulti-Language Sentiment Classification",
    url: "http://arxiv.org/pdf/1703.02504.pdf",
    authors:
      "\tJ. Deriu, A. Lucchi, V. De Luca, A. Severyn, S. Müller, M. Cieliebak, T. Hofmann, and M. Jaggi",
    locale:
      "Proceedings of the 26th International Conference on World Wide Web, 2017",
    year: 2017,
  },
  {
    title: "Sub-sampled Cubic Regularization for Non-convex Optimization",
    url: "https://arxiv.org/abs/1705.05933",
    authors: "    J. M. Kohler, A. Lucchi",
    locale: "International Conference on Machine Learning (ICML), 2017",
    year: 2017,
  },
  {
    title:
      "Computing Web-scale Topic Models using an Asynchronous Parameter Server",
    url: "https://dl.acm.org/citation.cfm?id=3084135",
    authors: "\tR. Jagerman, C. Eickhoff, M. de Rijke",
    locale:
      "Proceedings of the 40th ACM Conference on Research and Development in Information Retrieval (SIGIR), \t\t\t\t\t\t2017",
    year: 2017,
  },
  {
    title:
      "Fully Character-Level Neural Machine Translation without Explicit Segmentation",
    url: "https://arxiv.org/abs/1610.03017",
    authors: "        J. Lee, K. Cho and T. Hofmann",
    locale:
      "Transactions of the Association for Computational Linguistics (TACL), 2017",
    year: 2017,
  },
  {
    title:
      "Neural Multi-Step Reasoning for Question Answering on Semi-Structured Tables",
    url: "https://arxiv.org/abs/1702.06589.pdf",
    authors: "        T. Haug, O-E. Ganea and P. Grnarova",
    locale: "Arxiv, 2017",
    year: 2017,
  },
  {
    title:
      "Neural Document Embeddings for Intensive Care Patient Mortality Prediction",
    url: "https://arxiv.org/pdf/1612.00467v1",
    authors: "        P. Grnarova, F. Schmidt, S. L. Hyland and C. Eickhoff",
    locale: "NIPS 2016 Workshop on Machine Learning for Health",
    year: 2016,
  },
  {
    title:
      "Adaptive Newton Method for Empirical Risk Minimization to Statistical Accuracy",
    url: "http://papers.nips.cc/paper/6262-adaptive-newton-method-for-empirical-risk-minimization-to-statistical-accuracy",
    authors:
      "        A. Mokhtari, H. Daneshmand, A. Lucchi, T. Hofmann, A. Ribeiro",
    locale: "Advances in Neural Information Processing Systems (NIPS), 2016",
    year: 2016,
  },
  {
    title: "Content-aware Crowdsourcing Vote Aggregation",
    url: "https://www.researchgate.net/profile/Carsten_Eickhoff/publication/310457657_Content-aware_Crowdsourcing_Vote_Aggregation/links/582dc89a08ae004f74bcd64b/Content-aware-Crowdsourcing-Vote-Aggregation.pdf",
    authors: "\tP. Bansal, A. Barsan, M. Davtyan, and C. Eickhoff",
    locale:
      "Proceedings of the 15th Dutch-Belgian Information Retrieval Workshop (DIR), 2016",
    year: 2016,
  },
  {
    title:
      "Audio Based Bird Species Identification using Deep Learning Techniques",
    url: "http://ceur-ws.org/Vol-1609/16090547.pdf",
    authors: "    E. Sprengel, M. Jaggi, Y. Kilcher, T. Hofmann",
    locale: "CLEF (Working Notes) (pp. 547-559), 2016",
    year: 2016,
  },
  {
    title: "ETH Zurich at TREC Clinical Decision Support 2016",
    url: "http://trec.nist.gov/trec/pubs/trec25/papers/ETH-CL.pdf",
    authors:
      "    S. Greuter, P. Junker, L. Kuhn, F. Mance, V. Mermet, A. Rellstab and C. Eickhoff",
    locale: "TREC, 2016",
    year: 2016,
  },
  {
    title: "Active Content-Based Crowdsourcing Task Selection",
    url: "http://dl.acm.org/citation.cfm?id=2983716",
    authors: "\tP. Bansal, C. Eickhoff and T. Hofmann",
    locale:
      "Proceedings of the 25th ACM International Conference on Information and Knowledge Management (CIKM), 2016",
    year: 2016,
  },
  {
    title:
      "Privacy Leakage through Innocent Content Sharing in Online Social Networks",
    url: "https://arxiv.org/abs/1607.02714",
    authors: "\tM. Han Veiga, C. Eickhoff",
    locale:
      "Proceedings of the ACM SIGIR Workshop on Privacy Preserving Information Retrieval, 2016",
    year: 2016,
  },
  {
    title: "Implicit Negative Feedback in Clinical Information Retrieval",
    url: "https://arxiv.org/abs/1607.03296",
    authors: "\tL. Kuhn and C. Eickhoff",
    locale:
      " Proceedings of the ACM SIGIR Medical Information Retrieval (MedIR) Workshop, 2016",
    year: 2016,
  },
  {
    title: "Retrieval Techniques for Contextual Learning",
    url: "https://pdfs.semanticscholar.org/5f3b/d8b9b86713e381b242f8401ac1069596337f.pdf",
    authors: "\tN. Weingart and C. Eickhoff",
    locale: "Proceedings of the SIGIR Search as Learning Workshop (SAL), 2016",
    year: 2016,
  },
  {
    title: "Efficient Parallel Learning of Word2Vec",
    url: "http://arxiv.org/abs/1606.07822",
    authors: "\tJ. Vuurens, C. Eickhoff, A.P. de Vries",
    locale: "ICML 2016 ML Systems Workshop",
    year: 2016,
  },
  {
    title: "Starting Small - Learning with Adaptive Sample Sizes",
    url: "http://arxiv.org/abs/1603.02839",
    authors: "\tH. Daneshmand, A. Lucchi, T. Hofmann",
    locale: "International Conference on Machine Learning (ICML), 2016",
    year: 2016,
  },
  {
    title: "Primal-Dual Rates and Certificates",
    url: "http://arxiv.org/abs/1602.05205",
    authors: "\tC. Dünner, S. Forte, M. Takáč, M. Jaggi",
    locale: "International Conference on Machine Learning (ICML), 2016",
    year: 2016,
  },
  {
    title: "A Cross-Platform Collection of Social Network Profiles",
    url: "https://arxiv.org/abs/1607.03274",
    authors: "\tM. Han Veiga, C. Eickhoff",
    locale:
      "Proceedings of the 39th ACM Conference on Research and Development in Information Retrieval (SIGIR), 2016",
    year: 2016,
  },
  {
    title: "Robust Statistical Methods in Web Retrieval",
    url: "http://dl.acm.org/citation.cfm?id=2857663",
    authors: "\tC. Eickhoff, A. P. de Vries",
    locale: "ACM SIGWEB Newsletter, Issue Winter, 2016",
    year: 2016,
  },
  {
    title: "Probabilistic Local Expert Retrieval",
    url: "http://link.springer.com/chapter/10.1007/978-3-319-30671-1_17",
    authors: "\tW. Li, C. Eickhoff, A. P. de Vries",
    locale:
      "Proceedings of the 38th European Conference on Information Retrieval (ECIR), 2016",
    year: 2016,
  },
  {
    title: "Probabilistic Bag-Of-Hyperlinks Model for Entity Linking",
    url: "http://arxiv.org/abs/1509.02301",
    authors: "\tO-E. Ganea, M. Horlescu, A. Lucchi, C. Eickhoff, T. Hofmann",
    locale: "International World Wide Web Conference (WWW), 2016",
    year: 2016,
  },
  {
    title: "Pursuits in Structured Non-Convex Matrix Factorizations",
    url: "http://arxiv.org/abs/1602.04208",
    authors: "\tR. Khanna, M. Tschannen, M. Jaggi",
    locale: " arXiv:1602.04208, 2016",
    year: 2016,
  },
  {
    title:
      "L1-Regularized Distributed Optimization: A Communication-Efficient Primal-Dual Framework",
    url: "http://arxiv.org/abs/1512.04011",
    authors: "\tV. Smith, S. Forte, M. Jordan, M. Jaggi",
    locale: " arXiv:1512.04011, 2015",
    year: 2015,
  },
  {
    title:
      "On the Global Linear Convergence of Frank-Wolfe Optimization Variants",
    url: "http://arxiv.org/pdf/1511.05932",
    authors: "\tS. Lacoste-Julien and M. Jaggi",
    locale: "Neural Information Processing Systems (NIPS), 2015",
    image: "images/publications/linearFW.png",
    year: 2015,
  },
  {
    title: "Variance Reduced Stochastic Gradient Descent with Neighbors",
    url: "http://arxiv.org/abs/1506.03662",
    authors: "\tT. Hofmann, A. Lucchi, S. Lacoste-Julien, B. McWilliams",
    locale: "Neural Information Processing Systems (NIPS), 2015",
    year: 2015,
  },
  {
    title: "Eye-tracking Studies of Query Intent and Reformulation",
    url: "http://dir2015.nl/wp-content/uploads/sites/8/2015/11/DIR2015-proceedings.pdf#page=25",
    authors: "\tC. Eickhoff, S. Dungs and V. Tran",
    locale:
      "Proceedings of the 14th Dutch-Belgian Information Retrieval Workshop (DIR), 2015",
    year: 2015,
  },
  {
    title:
      "Exploiting Document Content for Efficient Aggregation of Crowdsourcing Votes",
    url: "http://dl.acm.org/citation.cfm?id=2806460",
    authors: "\tM. Davtyan, C. Eickhoff and T. Hofmann",
    locale:
      "Proceedings of the 24th ACM International Conference on Information and Knowledge Management (CIKM), 2015",
    year: 2015,
  },
  {
    title: "Adding vs. Averaging in Distributed Primal-Dual Optimization",
    url: "http://arxiv.org/pdf/1502.03508.pdf",
    authors: "\tC. Ma, V. Smith, M. Jaggi, M.I. Jordan, P. Richtárik, M. Takáč",
    locale: "International Conference on Machine Learning (ICML), 2015",
    image: "images/publications/add-vs-avg.png",
    year: 2015,
  },
  {
    title: "An Eye-Tracking Study of Query Reformulation",
    url: "http://dl.acm.org/citation.cfm?id=2766462.2767703",
    authors: "\tC. Eickhoff, S. Dungs and V. Tran",
    locale: "Proceedings of the 38th Annual ACM SIGIR Conference, 2015",
    image: "images/publications/eyegaze.png",
    year: 2015,
  },
  {
    title: "Modelling Term Dependence with Copulas",
    url: "http://dl.acm.org/citation.cfm?id=2766462.2767831",
    authors: "\tC. Eickhoff, A. de Vries and T. Hofmann ",
    locale: "Proceedings of the 38th Annual ACM SIGIR Conference, 2015",
    year: 2015,
  },
  {
    title: "A Variance Reduced Stochastic Newton Method",
    url: "http://arxiv.org/abs/1503.08316",
    authors: "\tA. Lucchi, B. McWilliams, T. Hofmann ",
    locale: " arXiv:1503.08316v1 [cs.LG], 2015",
    year: 2015,
  },
  {
    title: "Communication-Efficient Distributed Dual Coordinate Ascent",
    url: "http://arxiv.org/abs/1409.1458",
    authors:
      "\tM. Jaggi, V. Smith, J. Terhorst, S. Krishnan, T. Hofmann and M. I. Jordan",
    locale: "Neural Information Processing Systems (NIPS), 2014",
    year: 2014,
  },
  {
    title: "Modelling Complex Relevance Spaces with Copulas",
    url: "http://dl.acm.org/citation.cfm?id=2661925",
    authors: "\tC. Eickhoff and A. de Vries",
    locale:
      "Proceedings of the 23rd ACM International Conference on Information and Knowledge Management (CIKM), 2014",
    year: 2014,
  },
  {
    title: "Interactive Summarization of Social Media",
    url: "http://dl.acm.org/citation.cfm?id=2637002.2637050",
    authors: "\tW. Li, C. Eickhoff and A. de Vries",
    locale:
      "Proceedings of the 6th Conference on Information Interaction in Context (IIiX), 2014",
    year: 2014,
  },
  {
    title: "An Equivalence between the Lasso and Support Vector Machines",
    url: "http://arxiv.org/pdf/1303.1152.pdf",
    authors: "\tM. Jaggi",
    locale:
      "Regularization, Optimization, Kernels, and Support Vector Machines, 2014",
    image: "images/publications/lassosupportvector.jpg",
    year: 2014,
  },
  {
    title: "Crowd-Powered Experts",
    url: "http://dl.acm.org/citation.cfm?id=2594788",
    authors: "\tC. Eickhoff",
    locale:
      "Proceedings of the ECIR Workshop on Gamification for Information Retrieval (GamifIR), 2014",
    year: 2014,
  },
  {
    title:
      "Lessons from the Journey: A Query Log Analysis of Within-Session Learning",
    url: "http://dl.acm.org/citation.cfm?id=2556217",
    authors: "\tC. Eickhoff, J. Teevan, R. White and S. Dumais",
    locale:
      "Proceedings of the 7th ACM International Conference on Web Search and Data Mining (WSDM), 2014",
    year: 2014,
  },
  {
    title: "Geo-Spatial Domain Expertise in Microblogs",
    url: "http://link.springer.com/chapter/10.1007/978-3-319-06028-6_46",
    authors: "\tW. Li, C. Eickhoff and A. de Vries",
    locale:
      "Proceedings of the 36th European Conference on Information Retrieval (ECIR), 2014",
    year: 2014,
  },
  {
    title: "Revisiting Frank-Wolfe: Projection-Free Sparse Convex Optimization",
    url: "http://jmlr.org/proceedings/papers/v28/jaggi13-supp.pdf",
    authors: "\tM. Jaggi",
    locale: "International Conference on Machine Learning (ICML), 2013",
    image: "images/publications/revisiting-fw.jpg",
    year: 2013,
  },
  {
    title: "Block-Coordinate Frank-Wolfe Optimization for Structural SVMs",
    url: "http://jmlr.org/proceedings/papers/v28/lacoste-julien13-supp.pdf",
    authors: "\tS. Lacoste-Julien, M. Jaggi, M. Schmidt and P. Pletscher",
    locale: "International Conference on Machine Learning (ICML), 2013",
    year: 2013,
  },
  {
    title:
      "An Optimal Policy for Target Localization with Application to Electron Microscopy",
    url: "http://infoscience.epfl.ch/record/183773/files/icml2013.pdf",
    authors: "\tR. Sznitman, A. Lucchi, P. Frazier, B. Jedynak and P. Fua",
    locale: "International Conference on Machine Learning (ICML), 2013",
    image: "images/publications/electron-microscopy.jpg",
    year: 2013,
  },
  {
    title:
      "Learning for Structured Prediction Using Approximate Subgradient Descent with Working Sets",
    url: "http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=6619103",
    authors: "\tA. Lucchi Y. Li and P. Fua",
    locale:
      "IEEE Conference on Computer Vision and Pattern recognition (CVPR), 2013",
    year: 2013,
  },
  {
    title: "Copulas for Information Retrieval",
    url: "http://dl.acm.org/citation.cfm?id=2484066",
    authors: "\tC. Eickhoff, A. de Vries and K. Collins-Thompson",
    locale:
      "Proceedings of the 36th International ACM SIGIR Conference on Research and Development in Information Retrieval, 2013",
    year: 2013,
  },
  {
    title: "Increasing Cheat Robustness of Crowdsourcing Tasks",
    url: "http://link.springer.com/article/10.1007/s10791-011-9181-9",
    authors: "\tC. Eickhoff and A. de Vries",
    locale: "Information Retrieval 16.2: 121-137, 2013",
    year: 2013,
  },
  {
    title: "Joint Image and Word Sense Discrimination For Image Retrieval",
    url: "http://link.springer.com/chapter/10.1007/978-3-642-33718-5_10",
    authors: "\tA. Lucchi and J. Weston",
    locale:
      "Proceedings of the European Conference on Computer Vision (ECCV), 2012",
  },
  {
    title: "A Simple Algorithm for Nuclear Norm Regularized Problems",
    url: "http://www.icml2010.org/papers/196.pdf",
    authors: "\tM. Jaggi and M. Sulovsky",
    locale: "International Conference on Machine Learning (ICML), 2010",
  },
  {
    title:
      "Efficient Subwindow Search: A Branch and Bound Framework for Object Localization",
    url: "http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=5166448",
    authors: "\tC. H. Lampert, M. B. Blaschko and T. Hofmann",
    locale:
      "IEEE Trans. Pattern Anal. Mach. Intell. (PAMI) 31(12): 2129-2142, 2009",
  },
  {
    title: "Kernel methods in machine learning",
    url: "http://www.jstor.org/stable/25464664",
    authors: "\tT. Hofmann, B. Schölkopf and A. J. Smola",
    locale: "The Annals of Statistics. 36(3): 1171-1220, 2008",
  },
  {
    title:
      "Large Margin Methods for Structured and Interdependent Output Variables",
    url: "http://jmlr.org/papers/volume6/tsochantaridis05a/tsochantaridis05a.pdf",
    authors: "\tI. Tsochantaridis, T. Hofmann, T. Joachims and Y. Altun",
    locale: "Journal for Machine Learning Research, 2005",
  },
  {
    title: "Latent semantic models for collaborative filtering",
    url: "http://dl.acm.org/authorize?N83897",
    authors: "\tT. Hofmann",
    locale: "ACM Transactions on Information Systems. 22(1): 89-115, 2004",
  },
  {
    title: "Unsupervised Learning by Probabilistic Latent Semantic Analysis",
    url: "http://link.springer.com/article/10.1023/A:1007617005950",
    authors: "\tT. Hofmann",
    locale: "Journal of Machine Learning. 42: 177-196, 2001",
  },
  {
    title:
      "Unsupervised texture segmentation in a deterministic annealing framework",
    url: "http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=709593",
    authors: "\tT. Hofmann, J. Puzicha, and J. M. Buhmann",
    locale:
      "IEEE Transactions on Pattern Analysis and Machine Intelligence, 20(8): 803-818, 1998",
  },
  {
    title: "Pairwise Data Clustering by Deterministic Annealing",
    url: "http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=566806",
    authors: "\tT. Hofmann and J. M. Buhmann",
    locale:
      "IEEE Transactions on Pattern Analysis and Machine Intelligence, 19(1): 1-14, 1997",
  },
  {
    title: "The Mobile Robot Rhino",
    url: "http://www.aaai.org/ojs/index.php/aimagazine/article/viewArticle/1131",
    authors:
      "\tJ. M. Buhmann, W. Burgard, A. B. Cremers, D. Fox, T. Hofmann, F. Schneider, I. Strikos, and S. Thrun",
    locale: "AI Magazine, 16(1), 1995",
    image: "images/publications/rhino.jpg",
  },
];

const groupedPublications = Object.values(
  groupBy(
    publications.map((p) => ({ year: 0, ...p })),
    "year"
  )
).sort((a, b) => b[0].year - a[0].year);

// if Publication has blog add the link to the blog
export const PublicationCard = (publication) => (
  <DalabCard
    title={publication.title}
    subheader={publication.authors}
    image={publication.image}
    text={`Published in ${publication.locale}`}
    url={publication.blog_url ? publication.blog_url : publication.url}
    cardHeight={40}
  />
);

export default () => (
  <>
    {groupedPublications.map((group, idx) => (
      <>
        <React.Fragment key={idx}>
          <Box mt={2}>
            <Typography variant="h5">{group[0].year || "Older"}</Typography>
          </Box>
          <DalabGrid data={group} card={PublicationCard} />
        </React.Fragment>
      </>
    ))}
  </>
);
