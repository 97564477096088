import React from 'react';

import DalabGrid from './components/DalabGrid';
import DalabCard from './components/DalabCard';

import { publicUrl } from './utils';

export const projects = [
  // {
  //  title: "State Space Models for ECG Denoising",
  //  url: publicUrl('assets/2025_01_M_thesis_state_space_models_ecg.pdf'),
  //  image: 'images/projects/images/ecg_2_en.png'
  // }
  //{
  //  title: "Continual Learning and Neural Networks' Scaling Limit(s)",
  //  url: publicUrl('assets/thesis_continual_learning_and_scaling_limits.pdf'),
  //  image: 'images/projects/images/large_width_no_forget.png'
  //},
  //{
  // title: "Explaining the effect of pre-training on continual learning",
  // url: publicUrl('assets/ThesisProposalAICDALAB_GL.pdf'),
  // image: 'images/projects/images/CL_PRTR_project.png'
  //}
  //{
  //  title: "Finite vs. Infinite Fully Connected Networks",
  //  url: publicUrl('assets/Finite_vs_Infinite_Fully_Connected.pdf'),
  //  image: 'images/projects/images/finite_vs_infinite_image.png'
  // re-compile
  //},
  //{
  //  title: "Neural Networks and Their Eigenfunctions",
  //  url: publicUrl('assets/ntk_eigenfunctions_proposal.pdf'),
  //  image: 'images/projects/images/project_eigenfunctions_logo.png'
  //},
  // {
  //   title: "Student Assistant: The next-gen text prediction",
  //   url: publicUrl('assets/typewise.pdf'),
  //   image: 'images/projects/images/typewise.png'
  // },
  // {
  //   title: 'Semester Project: The Surprising Secrets of your City',
  //   url: publicUrl('assets/cities.pdf'),
  //   image: 'images/projects/images/zurich_satellite.png'
  // }, 
  // {
  //   title: 'Semester Project: Object Instance Segmentation using Global Reasoning',
  //   url: publicUrl('assets/rendering_project_proposal.pdf'),
  //   image: 'images/projects/images/rendering-project-proposal.png'
  // }, 
  // {
  //   title: 'Combining Neural Networks with Numerical Integrators',
  //   url: publicUrl('assets/UMD_GIS_Project_Proposal_Template.pdf'),
  //   image: 'images/projects/images/UMD_GIS_Project_Proposal_Template.png'
  // },
  // {
  //   title: 'Random Search Optimization on Riemannian Manifolds',
  //   url: 'http://www.da.inf.ethz.ch/files/Msc_Riemmanian_opt.pdf',
  //   image: 'images/projects/images/algorithm_manifold.png'
  // },
  // {
  //   title: 'Analysis of the decision boundary of deep networks',
  //   url: publicUrl('assets/dezfooli_decision_boundary.pdf'),
  //   image: 'images/projects/images/dezfooli_decision_boundary.png'
  // },
  // {
  //   title: 'Measuring the false sense of security',
  //   url: publicUrl('assets/sense_of_security.pdf'),
  //   image: 'images/projects/images/sense_of_security.png'
  // },
  // {
  //   title: 'Detection of grape cutting point using vision, 3D data, and learning (joint with Marco Hutter’s lab)',
  //   url: publicUrl('assets/grapes.pdf'),
  //   image: 'images/projects/images/grapes.png'
  // },
  // {
  //   title: 'Convergence of Training Deep Non-Linear ResNets under Interpolation',
  //   url: 'http://www.da.inf.ethz.ch/files/Msc_ResNet.pdf',
  //   image: 'images/projects/images/resnets.png'
  // },
  // {
  //   title: 'Injectivity of Deep Non-Linear Networks and the Role of Normalization',
  //   url: 'http://www.da.inf.ethz.ch/files/Msc_Injectivity.pdf',
  //   image: 'images/projects/images/injective-function.png'
  // },
  // {
  //   title: 'Understanding random neural networks',
  //   url: 'http://www.da.inf.ethz.ch/files/random_neural_nets.pdf',
  //   image: 'images/projects/images/randomNeuralNetworks.png'
  // },
  // {
  //   title: 'Understanding the Effect of Noise in Training Deep Neural Networks',
  //   url: 'http://www.da.inf.ethz.ch/files/Msc_Noise_DNN.pdf',
  //   image: 'images/projects/images/brownian.png'
  // }
  //  {
  //     title: 'Studying the Effect of Class Imbalance in Classification with Deep Networks',
  //     url: 'http://www.da.inf.ethz.ch/files/Msc_class_imbalance.pdf',
  //     image: 'images/projects/images/imbalanced.png'
  //   }
  // {
  //   title: 'Deep Set for Video Game Outcome Prediction',
  //   url: 'http://www.da.inf.ethz.ch/files/ds4vgop.pdf',
  //   image: 'images/projects/images/game_prediction.png'
  // } 
  // {
  //   title: 'Project in Prof. Püschel\'s Group: Meet/Join Lattice Convolutional Neural Networks',
  //   url: 'http://www.da.inf.ethz.ch/files/gdl.pdf',
  //   image: 'images/projects/images/gdl.png'
  // }
];

export const ProjectCard = project =>
  <DalabCard title={project.title} subheader={project.type} image={project.image} text={project.description} url={project.url} cardHeight={45} />

export default () =>
  <DalabGrid data={projects} card={ProjectCard} emptyMsg='There are no open projects currently. For specific ideas feel free to contact us individually.' />
